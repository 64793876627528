import { lazy, Suspense } from 'react';
import { Router } from '@reach/router';
import { Provider as StoreProvider } from 'react-redux';
import './App.css';
import { Spinner } from './components/Spinner';
import { initializeStore } from './store';

const BASE_PATH = process.env.REACT_APP_ROUTE_BASE_PATH || '/';

const store = initializeStore();

const WaitingComponent = ({
  Component,
  path,
  ...props
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: any;
  path: string;
}) => (
  <Suspense
    fallback={
      <div
        className="flex items-center align-middle"
        style={{ width: '100vw', height: '100vh' }}
      >
        <Spinner />
      </div>
    }
  >
    <Component path={path} {...props} />
  </Suspense>
);

const Dashboard = lazy(() => import('./views/Dashboard'));
const NewJob = lazy(() => import('./views/NewJob'));
const JobList = lazy(() => import('./views/JobList'));
const Login = lazy(() => import('./views/Login'));

function App(): JSX.Element {
  return (
    <StoreProvider store={store}>
      <Suspense fallback={<Spinner />}>
        <Router basepath={BASE_PATH}>
          <WaitingComponent Component={Dashboard} path="/" />
          <WaitingComponent Component={NewJob} path="/new-job" />
          <WaitingComponent Component={NewJob} path="/job/:id" />
          <WaitingComponent Component={NewJob} path="/job/:id/copy" />
          <WaitingComponent Component={JobList} path="/jobs" />
          <WaitingComponent Component={Login} path="/login" />
        </Router>
      </Suspense>
    </StoreProvider>
  );
}

export default App;
