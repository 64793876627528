/* eslint-disable @typescript-eslint/no-explicit-any */
import { ForkEffect } from '@redux-saga/core/effects';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { notify } from '../../components/Notification';
import {
  productCategoryFromProductType,
  productFeatures,
} from '../../constants';

import { axiosInstance } from '../../services/apiClient';
import { IStore } from '../types';
import { jobActions } from './job.reducer';
import { mixpanel, trackEvent } from '../../services/tracking';

const types = {
  SUBMIT_JOB: 'SUBMIT_JOB',
  GET_PIPELINE_STATUS: 'GET_PIPELINE_STATUS',
  GET_EXISTENT_SEEDLIST: 'GET_EXISTENT_SEEDLIST',
  GET_NON_EXISTENT_SEEDLIST: 'GET_NON_EXISTENT_SEEDLIST',
  SEND_FEEDBACK: 'SEND_FEEDBACK',
  GET_JOB_ITEM: 'GET_JOB_ITEM',
  GET_JOB_ITEM_AND_COPY: 'GET_JOB_ITEM_AND_COPY',

  GET_EXISTENT_COMPETITOR: 'GET_EXISTENT_COMPETITOR',
  GET_NON_EXISTENT_COMPETITOR: 'GET_NON_EXISTENT_COMPETITOR',
};

interface SubmitJob {
  type: string;
}

export const jobSagaActions = {
  submitJob: (): SubmitJob => ({
    type: types.SUBMIT_JOB,
  }),
  getPipelineStatus: (): { type: string } => ({
    type: types.GET_PIPELINE_STATUS,
  }),
  getExistingSeedlist: (): { type: string } => ({
    type: types.GET_EXISTENT_SEEDLIST,
  }),
  getNonExistingSeedlist: (): { type: string } => ({
    type: types.GET_NON_EXISTENT_SEEDLIST,
  }),

  getExistingCompetitor: (): { type: string } => ({
    type: types.GET_EXISTENT_COMPETITOR,
  }),

  getNonExistingCompetitor: (): { type: string } => ({
    type: types.GET_NON_EXISTENT_COMPETITOR,
  }),

  sendFeedback: (payload: any): { type: string; payload: any } => ({
    type: types.SEND_FEEDBACK,
    payload,
  }),

  getJob: (payload: any): { type: string; payload: any } => ({
    type: types.GET_JOB_ITEM,
    payload,
  }),

  getJobAndCopy: (payload: any): { type: string; payload: any } => ({
    type: types.GET_JOB_ITEM_AND_COPY,
    payload,
  }),
};

function* submitJobSaga(action: SubmitJob): any {
  console.log('Submit job in saga');

  try {
    yield put(jobActions.setLoading(true));
    const { jobForm } = yield select((store: IStore) => store.job);
    console.log({ jobForm });

    // translate job form
    const data: any = {
      spec_range: {},
      important_spec: [],
      market_share_spec: [],
      product_spec: [],
      product_type: jobForm.product,
      m: Number(jobForm.topMResult),
      n: Number(jobForm.topNSeedlist),
      duration: jobForm.selectedDuration,
    };

    Object.keys(jobForm.features).forEach((key: string) => {
      const name = jobForm.features[key].name;
      data.product_spec.push(name);
      if (jobForm.features[key].important) {
        data.important_spec.push(name);
      }
      if (jobForm.features[key].marketShare) {
        data.market_share_spec.push(name);
      }
      if (jobForm.features[key].type === 'select') {
        data.spec_range[name] = jobForm.features[key].classValue;
        return;
      }

      data.spec_range[name] = [
        Number(jobForm.features[key]['min']),
        Number(jobForm.features[key]['max']),
      ];
    });

    console.log('Request data', {
      data,
    });
    const res = yield call(axiosInstance.post, `/job`, data, {
      withCredentials: true,
    });
    yield put(jobActions.setJobId(res.data.data?.job?.id));
    yield put(jobActions.setImportantFeature(data.important_spec));
    yield put(
      jobActions.setPipelineTask(
        res.data.data?.pipeline_response?.message ?? []
      )
    );
    console.log({ mixpanel });
    yield call(
      trackEvent,
      'Run generate seed list',
      {}
      // , {
      //   data,
      // }
    );
    yield put(jobActions.setCacheId(res.data.data?.cache_id));
    yield put(jobActions.setStep(3));
  } catch (error) {
    console.log('Get store detail error', {
      error,
    });
  }
  yield put(jobActions.setLoading(false));
}

function* getPipelineStatusSaga(): any {
  try {
    yield put(jobActions.setLoading(true));
    const { jobForm } = yield select((store: IStore) => store.job);
    const { jobId } = jobForm;
    const res = yield call(axiosInstance.get, `/job/${jobId}/status`, {
      withCredentials: true,
    });
    yield put(jobActions.setPipelineTask(res.data.data ?? []));
  } catch (error) {
    console.log('Get store detail error', {
      error,
    });
  }
  yield put(jobActions.setLoading(false));
}

function* getExistingSeedlistSaga(): any {
  try {
    yield put(jobActions.setLoading(true));
    const { jobForm } = yield select((store: IStore) => store.job);
    const { jobId } = jobForm;

    const res = yield call(axiosInstance.get, `/job/${jobId}/existent`, {
      withCredentials: true,
    });
    yield put(jobActions.setExistingSeedList(res.data.data ?? []));
  } catch (error) {
    console.log('Get store detail error', {
      error,
    });
  }
  yield put(jobActions.setLoading(false));
}

function* getNonExistingSeedlistSaga(): any {
  try {
    yield put(jobActions.setLoading(true));
    const { jobForm } = yield select((store: IStore) => store.job);
    const { jobId } = jobForm;

    const res = yield call(axiosInstance.get, `/job/${jobId}/non-existent`, {
      withCredentials: true,
    });
    yield put(jobActions.setNonExistingSeedList(res.data.data ?? []));
  } catch (error) {
    console.log('Get store detail error', {
      error,
    });
  }
  yield put(jobActions.setLoading(false));
}

function* sendExpertFeedbackSaga({ payload }: any): any {
  try {
    yield put(jobActions.setLoading(true));
    const { jobForm } = yield select((store: IStore) => store.job);
    const { jobId } = jobForm;

    yield call(axiosInstance.post, `/job/${jobId}/feedback`, payload, {
      withCredentials: true,
    });
    notify({
      content: 'Feedback has been recorded',
    });
    // yield put(jobActions.setNonExistingSeedList(res.data.data ?? []));
  } catch (error) {
    console.log('Get store detail error', {
      error,
    });
  }
  yield put(jobActions.setLoading(false));
}

function* getJobItemSaga({ payload }: any): any {
  try {
    yield put(jobActions.setLoading(true));

    const response = yield call(axiosInstance.get, `/job/${payload}`, {
      withCredentials: true,
    });

    // job
    yield put(jobActions.setJobId(response.data?.data?.id));
    yield put(jobActions.setProduct(response.data?.data?.input?.product_type));
    yield put(
      jobActions.setImportantFeature(response.data?.data?.input?.important_spec)
    );
    yield put(jobSagaActions.getPipelineStatus());

    const {
      important_spec = [],
      market_share_spec = [],
      product_spec = [],
      spec_range = {},
      product_type = '',
      m,
      n,
      duration,
    } = response.data?.data?.input ?? {};
    const date = response.data?.data?.date_time ?? '';

    const category = productCategoryFromProductType[product_type];

    const specs: any = {};

    const features = productFeatures[category];

    features.forEach((ft: any) => {
      console.log({
        product_spec,
        k: ft.key,
      });
      if (product_spec.indexOf(ft.name) > -1) {
        const obj: any = {
          name: ft.name,
          type: ft.type,
        };

        if (obj.type === 'select') {
          obj['classValue'] = spec_range[ft.name];
        } else {
          obj.min = spec_range[ft.name][0];
          obj.max = spec_range[ft.name][1];
        }

        if (important_spec.indexOf(ft.name) > -1) {
          obj.important = true;
        }
        if (market_share_spec.indexOf(ft.name) > -1) {
          obj.marketShare = true;
        }

        specs[ft.key] = obj;
      }
    });

    yield put(jobActions.setFeature(specs));
    yield put(jobActions.setTopMResult(m));
    yield put(jobActions.setTopNSeedList(n));
    yield put(jobActions.setSelectedDuration(duration));
    yield put(jobActions.setJobDateTime(date));
    // try {
    //   yield put(jobSagaActions.getExistingCompetitor());
    // } catch (error) {}
    // try {
    //   yield put(jobSagaActions.getNonExistingCompetitor());
    // } catch (error) {}
  } catch (error) {
    console.log('Get store detail error', {
      error,
    });
  }
  yield put(jobActions.setLoading(false));
}

function* getJobItemAndCopySaga({ payload }: any): any {
  try {
    yield put(jobActions.setLoading(true));

    const response = yield call(axiosInstance.get, `/job/${payload}`, {
      withCredentials: true,
    });

    console.log({ response });

    // job
    yield put(jobActions.setJobId(response.data?.data?.id));
    yield put(jobActions.setProduct(response.data?.data?.input?.product_type));
    yield put(
      jobActions.setProductCategory(
        productCategoryFromProductType[response.data?.data?.input?.product_type]
      )
    );
    yield put(
      jobActions.setImportantFeature(response.data?.data?.input?.important_spec)
    );

    const {
      important_spec = [],
      market_share_spec = [],
      product_spec = [],
      spec_range = {},
      product_type = '',
      m,
      n,
      duration,
    } = response.data?.data?.input ?? {};

    const category = productCategoryFromProductType[product_type];

    const specs: any = {};

    const features = productFeatures[category];

    features.forEach((ft: any) => {
      console.log({
        product_spec,
        k: ft.key,
      });
      if (product_spec.indexOf(ft.name) > -1) {
        const obj: any = {
          name: ft.name,
          type: ft.type,
        };

        if (obj.type === 'select') {
          obj['classValue'] = spec_range[ft.name];
        } else {
          obj.min = spec_range[ft.name][0];
          obj.max = spec_range[ft.name][1];
        }

        if (important_spec.indexOf(ft.name) > -1) {
          obj.important = true;
        }
        if (market_share_spec.indexOf(ft.name) > -1) {
          obj.marketShare = true;
        }

        specs[ft.key] = obj;
      }
    });

    yield put(jobActions.setFeature(specs));
    yield put(jobActions.setTopMResult(m));
    yield put(jobActions.setTopNSeedList(n));
    yield put(jobActions.setSelectedDuration(duration));

    yield put(jobActions.setStep(2));
    yield put(jobSagaActions.getPipelineStatus());
  } catch (error) {
    console.log('Get store detail error', {
      error,
    });
  }
  yield put(jobActions.setLoading(false));
}

function* getExistingSeedlistCompetitorSaga(): any {
  try {
    yield put(jobActions.setLoading(true));
    const { jobForm } = yield select((store: IStore) => store.job);
    const { jobId } = jobForm;

    const res = yield call(
      axiosInstance.get,
      `/job/${jobId}/competitor_recommendation/existent`,
      {
        withCredentials: true,
      }
    );
    yield put(jobActions.setExistingSeedListCompetitor(res.data.data ?? []));
  } catch (error) {
    console.log('Get store detail error', {
      error,
    });
  }
  yield put(jobActions.setLoading(false));
}

function* getNonExistingSeedlistCompetitorSaga(): any {
  try {
    yield put(jobActions.setLoading(true));
    const { jobForm } = yield select((store: IStore) => store.job);
    const { jobId } = jobForm;

    const res = yield call(
      axiosInstance.get,
      `/job/${jobId}/competitor_recommendation/non_existent`,
      {
        withCredentials: true,
      }
    );
    yield put(jobActions.setNonExistingSeedListCompetitor(res.data.data ?? []));
  } catch (error) {
    console.log('Get store detail error', {
      error,
    });
  }
  yield put(jobActions.setLoading(false));
}

export default function* detailSaga(): Generator<ForkEffect<void>> {
  yield takeLatest(types.SUBMIT_JOB, submitJobSaga) as any;
  yield takeLatest(types.GET_PIPELINE_STATUS, getPipelineStatusSaga) as any;
  yield takeLatest(types.GET_EXISTENT_SEEDLIST, getExistingSeedlistSaga) as any;
  yield takeLatest(
    types.GET_NON_EXISTENT_SEEDLIST,
    getNonExistingSeedlistSaga
  ) as any;

  yield takeLatest(types.SEND_FEEDBACK, sendExpertFeedbackSaga) as any;
  yield takeLatest(types.GET_JOB_ITEM, getJobItemSaga) as any;
  yield takeLatest(types.GET_JOB_ITEM_AND_COPY, getJobItemAndCopySaga) as any;
  yield takeLatest(
    types.GET_EXISTENT_COMPETITOR,
    getExistingSeedlistCompetitorSaga
  ) as any;
  yield takeLatest(
    types.GET_NON_EXISTENT_COMPETITOR,
    getNonExistingSeedlistCompetitorSaga
  ) as any;
}
