import { createSlice } from '@reduxjs/toolkit';
import { JobStore } from './job.types';

export const initiaJobState: JobStore = {
  jobForm: {
    productCategory: '',
    product: '',
    features: {},
    topNSeedlist: 0,
    topMResult: 0,
    selectedDuration: 12,
    importantFeature: [],
  },
  runningPipeline: {
    tasks: [],
  },
  step: 0,
  loading: false,

  existentSeedList: [],
  nonExistentSeedList: [],
  existentSeedListCompetitor: [],
  nonExistentSeedListCompetitor: [],
};

export const JobSlide = createSlice({
  name: 'Job slice',
  initialState: initiaJobState,
  reducers: {
    setProductCategory: (state, action) => {
      state.jobForm.productCategory = action.payload;
    },
    setProduct: (state, action) => {
      state.jobForm.product = action.payload;
    },
    setFeature: (state, action) => {
      state.jobForm.features = action.payload;
    },
    setTopNSeedList: (state, action) => {
      state.jobForm.topNSeedlist = action.payload;
    },
    setTopMResult: (state, action) => {
      state.jobForm.topMResult = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStep: (state, action) => {
      console.log(state);
      state.step = action.payload;
    },
    setJobId: (state, action) => {
      state.jobForm.jobId = action.payload;
    },

    setPipelineTask: (state, action) => {
      state.runningPipeline.tasks = action.payload;
    },

    setExistingSeedList: (state, action) => {
      state.existentSeedList = action.payload;
    },
    setNonExistingSeedList: (state, action) => {
      state.nonExistentSeedList = action.payload;
    },

    setCacheId: (state, { payload }) => {
      state.cache_id = payload;
    },

    setSelectedDuration: (state, { payload }) => {
      state.jobForm.selectedDuration = payload;
    },

    setImportantFeature: (state, { payload }) => {
      state.jobForm.importantFeature = payload;
    },

    setExistingSeedListCompetitor: (state, { payload }) => {
      state.existentSeedListCompetitor = payload;
    },

    setNonExistingSeedListCompetitor: (state, { payload }) => {
      state.nonExistentSeedListCompetitor = payload;
    },
    setJobDateTime: (state, { payload }) => {
      state.jobForm.dateTime = payload;
    },
  },
});

// Actions
export const jobActions = JobSlide.actions;

export default JobSlide.reducer;
