/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import detail from './detail/detail.saga';
import job from './job/job.saga';
export default function* mainSaga(): Generator<
  AllEffect<ForkEffect<any>>,
  void,
  unknown
> {
  yield all([fork(detail), fork(job)]);
}
