import mixpanel from 'mixpanel-browser';
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY ?? '', { debug: true });

export const trackEvent = (name: string, data: any) => {
  if (!mixpanel.hasOwnProperty('get_distinct_id')) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY ?? '', { debug: true });
  }
  mixpanel.track(name, data);
};
export { mixpanel };
