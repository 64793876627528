const productCategories: { key: string; value: string; enable?: boolean }[] = [
  { key: 'capacitors', value: 'Capacitors', enable: true },
  { key: 'switches', value: 'Switches', enable: false },
  { key: 'resistors', value: 'Resistors' },
  { key: 'relays', value: 'Relays', enable: true },
];

const productCategoryNameByKey: { [key: string]: string } =
  productCategories.reduce((obj: { [key: string]: string }, val) => {
    obj[val['key']] = val['value'];
    return obj;
  }, {});

const productsByCategory: {
  [key: string]: {
    key: string;
    value: string;
    enable?: boolean;
    data?: boolean;
  }[];
} = {
  capacitors: [
    {
      key: 'polimer',
      value: 'Polymer',
    },
    {
      key: 'cap',
      value: 'Cap',
    },
    {
      key: 'lytic',
      value: 'Lytic',
      enable: true,
      data: true,
    },
    {
      key: 'tantalum',
      value: 'Tantalum',
    },
    {
      key: 'poscap',
      value: 'Poscap',
    },
    {
      key: 'oscon',
      value: 'Oscon',
    },
  ],
  switches: [
    {
      key: 'tact',
      value: 'Tact',
    },
  ],
  relays: [
    {
      key: 'Power_Relay',
      value: 'Power Relay',
      enable: true,
    },
    {
      key: 'Auto_Relay',
      value: 'Auto Relay',
      enable: true,
    },
    {
      key: 'RF_Relay',
      value: 'RF Relay',
      enable: true,
    },
    {
      key: 'Signal_Relay',
      value: 'Signal Relay',
      enable: true,
    },
    {
      key: 'photoMOS',
      value: 'PhotoMOS',
      enable: true,
    },
  ],
};

const productNameByKey: { [key: string]: string } = Object.keys(
  productsByCategory
).reduce((obj: { [key: string]: string }, key: string) => {
  productsByCategory[key].forEach((product) => {
    obj[product.key] = product.value;
  });
  return obj;
}, {});

const productCategoryFromProductType: { [key: string]: string } = Object.keys(
  productsByCategory
).reduce((o: any, key: string) => {
  productsByCategory[key].forEach((product) => {
    o[product.key] = key;
  });
  return o;
}, {});

const productFeatures: any = {
  capacitors: [
    {
      name: 'Cap',
      key: 'cap',
    },
    {
      name: 'ToleranceL',
      key: 'tolerancel',
    },
    {
      name: 'ToleranceH',
      key: 'toleranceh',
    },
    {
      name: 'Vol_V',
      key: 'vol_v',
    },
    {
      name: 'life',
      key: 'life',
    },
    {
      name: 'OpTempL',
      key: 'optempl',
    },
    {
      name: 'OpTempH',
      key: 'optemph',
    },
    {
      name: 'Polarization',
      key: 'polarization',
      type: 'select',
    },
    // {
    //   name: 'Ratings',
    //   key: 'ratings',
    //   type: 'select',
    // },
    {
      name: 'Application',
      key: 'application',
      type: 'select',
    },
    {
      name: 'Ripple1',
      key: 'ripple1',
    },
    {
      name: 'Ripple2',
      key: 'ripple2',
    },
    {
      name: 'Height',
      key: 'height',
    },
    {
      name: 'Mounting',
      key: 'mounting',
      type: 'select',
    },
    {
      name: 'PackageCase',
      key: 'package_case',
      type: 'select',
    },
  ],
  switches: [
    {
      name: 'Package',
      key: 'package',
      type: 'select',
    },
    {
      name: 'Current',
      key: 'current',
    },
    {
      name: 'Voltage',
      key: 'voltage',
      type: 'select',
    },
    {
      name: 'Ron',
      key: 'ron',
    },
    {
      name: 'Mounting',
      key: 'mounting',
      type: 'select',
    },
    {
      name: 'Termination',
      key: 'termination',
      type: 'select',
    },
    {
      name: 'Vin',
      key: 'vin',
      type: 'select',
    },
    {
      name: 'Circuit',
      key: 'circuit',
      type: 'select',
    },
    {
      name: 'OutputType',
      key: 'output_type',
      type: 'select',
    },
  ],
  resistors: [],
  photoMOS: [
    {
      name: 'Package',
      key: 'package',
      type: 'select',
    },
    {
      name: 'Current',
      key: 'current',
    },
    {
      name: 'Voltage',
      key: 'voltage',
    },
    {
      name: 'Ron',
      key: 'ron',
      type: 'select',
    },
    {
      name: 'Mounting',
      key: 'mounting',
      type: 'select',
    },
    {
      name: 'Termination',
      key: 'termination',
      type: 'select',
    },
    {
      name: 'Circuit',
      key: 'circuit',
      type: 'select',
    },
    {
      name: 'OutputType',
      key: 'output_type',
      type: 'select',
    },
  ],
  Auto_Relay: [
    {
      name: 'CoilType',
      key: 'CoilType',
      type: 'select',
    },
    {
      name: 'CoilVoltage',
      key: 'CoilVoltage',
    },
    {
      name: 'ContactForm',
      key: 'ContactForm',
      type: 'select',
    },
    {
      name: 'ContactRating',
      key: 'ContactRating',
    },
    {
      name: 'SwitchingVoltage',
      key: 'SwitchingVoltage',
    },
    {
      name: 'TempMax',
      key: 'TempMax',
    },
    {
      name: 'Termination',
      key: 'Termination',
      type: 'select',
    },
  ],
  RF_Relay: [
    {
      name: 'CoilType',
      key: 'CoilType',
      type: 'select',
    },
    {
      name: 'CoilVoltage',
      key: 'CoilVoltage',
    },
    {
      name: 'ContactForm',
      key: 'ContactForm',
      type: 'select',
    },
    {
      name: 'ContactRating',
      key: 'ContactRating',
    },
    {
      name: 'SwitchingVoltage',
      key: 'SwitchingVoltage',
    },
    {
      name: 'TempMax',
      key: 'TempMax',
    },
    {
      name: 'Termination',
      key: 'Termination',
      type: 'select',
    },
  ],
  Power_Relay: [
    {
      name: 'CoilType',
      key: 'CoilType',
      type: 'select',
    },
    {
      name: 'CoilVoltage',
      key: 'CoilVoltage',
    },
    {
      name: 'ContactForm',
      key: 'ContactForm',
      type: 'select',
    },
    {
      name: 'ContactRating',
      key: 'ContactRating',
    },
    {
      name: 'SwitchingVoltageVAC',
      key: 'SwitchingVoltageVAC',
      type: 'select',
    },
    {
      name: 'SwitchingVoltageVDC',
      key: 'SwitchingVoltageVDC',
      type: 'select',
    },
    {
      name: 'TempMax',
      key: 'TempMax',
    },
    {
      name: 'Termination',
      key: 'Termination',
      type: 'select',
    },
  ],
  Signal_Relay: [
    {
      name: 'CoilType',
      key: 'CoilType',
      type: 'select',
    },
    {
      name: 'CoilVoltage',
      key: 'CoilVoltage',
    },
    {
      name: 'ContactForm',
      key: 'ContactForm',
      type: 'select',
    },
    {
      name: 'ContactRating',
      key: 'ContactRating',
    },
    {
      name: 'SwitchingVoltageVAC',
      key: 'SwitchingVoltageVAC',
      type: 'select',
    },
    {
      name: 'SwitchingVoltageVDC',
      key: 'SwitchingVoltageVDC',
      type: 'select',
    },
    {
      name: 'TempMax',
      key: 'TempMax',
    },
    {
      name: 'Termination',
      key: 'Termination',
      type: 'select',
    },
  ],
};

const minMaxFeatureValue: any = {
  cap: {
    min: 1e-7,
    max: 1.3,
  },
  tolerancel: {
    min: -0.2,
    max: 0,
  },
  toleranceh: {
    min: 0.1,
    max: 1.5,
  },
  vol_v: {
    min: 4,
    max: 630,
  },
  life: {
    min: 1000,
    max: 37000,
  },
  optempl: {
    min: -55,
    max: -22,
  },
  optemph: {
    min: 65,
    max: 175,
  },
  ripple1: {
    min: 1,
    max: 43.1,
  },
  ripple2: {
    min: 0.0015,
    max: 53.62,
  },
  diameter: {
    max: 0.018,
    min: 0.016,
  },
  height: {
    min: 0.00395,
    max: 254,
  },

  pin: {
    min: 4,
    max: 16,
  },
  current: {
    min: 0.02,
    max: 20,
  },
  voltage: {
    min: 20,
    max: 1500,
  },
  ron: {
    min: 0.0033,
    max: 500.0,
  },
};

const sensorUnit: any = {
  pin: '',
  current: 'Ampere',
  voltage: 'Volt',
  ron: 'Ohm',
  package: '',
  mounting: '',
  termination: '',
  circuit: '',
  outputType: '',
  vin: 'Volt',
  cap: 'microfarad (uF)',
  tolerancel: '',
  toleranceh: '',
  vol_v: 'V',
  life: '',
  optempl: '',
  optemph: '',
  ripple1: '',
  ripple2: '',
  diameter: 'm',
  height: 'm',
  polarization: '',
  ratings: '',
  application: '',
  figure: '',
  package_case: '',
  output_type: '',
};

const graphColors = [
  '#AA0DFE',
  '#3283FE',
  '#85660D',
  '#782AB6',
  '#565656',
  '#1C8356',
  '#16FF32',
  '#F7E1A0',
  '#E2E2E2',
  '#1CBE4F',
  '#C4451C',
  '#DEA0FD',
  '#FE00FA',
  '#325A9B',
  '#FEAF16',
  '#F8A19F',
  '#90AD1C',
  '#F6222E',
  '#1CFFCE',
  '#2ED9FF',
  '#B10DA1',
  '#C075A6',
  '#FC1CBF',
  '#B00068',
  '#FBE426',
  '#FA0087',
];

const specsData: any = {
  lytic: {
    ranges: {
      Cap: [0.1, 1500000.0],
      ToleranceL: [-0.9, -0.1],
      ToleranceH: [0.1, 0.9],
      Vol_V: [4.0, 700.0],
      life: [1000.0, 20000.0],
      OpTempL: [-55.0, -25.0],
      OpTempH: [85.0, 175.0],
      Polarization: ['Polar', 'Bi-Polar'],
      // Ratings: ['AEC-Q200'],
      Application: [
        'General Purpose',
        'Automotive',
        'Audio',
        'High Temperature Reflow',
        'Automotive High Temperature Reflow',
        'Automotive Bypass Decoupling',
        'Bypass Decoupling',
        'Motor Start',
        'Audio Automotive',
      ],
      Ripple1: [1.0, 998.3],
      Ripple2: [1.0, 1275.0],
      Height: [0.156, 10.0],
      Mounting: [
        'Through Hole',
        'Surface Mount',
        'Chassis Mount',
        'Through Hole Press-Fit',
      ],
      PackageCase: [
        'Radial Can',
        'Radial Can - Snap-In',
        'Radial Can - Snap-In - 5 Lead',
        'Radial Can - Snap-In - 4 Lead',
        'Radial Can - SMD',
        'Axial Can',
        'Radial Can - Screw Terminals',
        'FlatPack Tabbed',
        'FlatPack',
        'Nonstandard SMD',
        'Radial Can - Press-Fit - 5 Lead',
        'Radial Can - Press-Fit - 4 Lead',
        'Axial',
        'Radial Can - 4 Lead',
        'Radial Can - Snap-In - 3 Lead',
        'Radial Can - 5 Lead',
        'Radial',
      ],
    },
    units: {
      Cap: 'microfarad (uF)',
      ToleranceL: '',
      ToleranceH: '',
      Vol_V: 'Volt',
      life: 'Hours',
      OpTempL: '°C',
      OpTempH: '°C',
      Polarization: '',
      Ratings: '',
      Application: '',
      Ripple1: '',
      Ripple2: '',
      Height: 'mm',
      Mounting: '',
      PackageCase: '',
    },
  },
  photoMOS: {
    ranges: {
      OutputType: ['AC', 'AC DC', 'DC'],
      Circuit: [
        '3PST-NO (3 Form A)',
        'DPST-NO (2 Form A)',
        'DPST-NO/NC (1 Form A and B)',
        'SPDT (1 Form C)',
        'SPST-NC (1 Form B)',
        'SPST-NO (1 Form A)',
        'SPST-NO (1 Form A) x 4',
      ],
      Ron: [
        'A 0-90 mOhms',
        'B 100-950 mOhms',
        'C 1000-4500 mOhms',
        'D 5000-9500 mOhms',
        'E 10K-18K mOhms',
        'F 20K-45K mOhms',
        'G 50k-500K mOhms',
        'H Unknown',
      ],
      Mounting: [
        'DIN Rail',
        'Socketable',
        'Surface Mount',
        'Through Hole'
      ],
      Voltage: [5.0, 1700.0],
      Current: [3.0, 38000.0],
      Termination: ['Gull Wing', 'PC Pin', 'Screw Terminal', 'SMD (SMT) Tab'],
      Package: [
        '4-DIP',
        '4-LDFN',
        '4-SIP',
        '4-SMD',
        '4-SOP',
        '6-DIP',
        '6-SMD',
        '6-SOP',
        '8-DIP',
        '8-SIP',
        '8-SMD',
        '8-SOP',
        'Hockey Puck',
        'i4-Pac-4 Isolated',
      ],
    },
    units: {
      Package: '',
      Current: 'Ampere',
      Voltage: 'Volt',
      Ron: 'Ohm',
      Mounting: '',
      Termination: '',
      Vin: 'Volt',
      Circuit: '',
      OutputType: '',
    },
  },
  Auto_Relay: {
    ranges: {
      CoilType: ['Non Latching', 'Latching Single Coil', 'Latching Dual Coil'],
      CoilVoltage: [6, 110],
      ContactForm: [
        '1 Form A',
        '1 Form C',
        '1 Form C x 2',
        '2 Form A',
        '2 Form C',
        '1 Form U',
        '1 Form B',
      ],
      ContactRating: [6.0, 300.0],
      SwitchingVoltage: [0.0, 500.0],
      TempMax: [70.0, 125.0],
      Termination: [
        'Through Hole',
        'Plug In',
        'Screw Terminal',
        'Surface Mount',
        'Tab Terminal',
        'Other',
      ],
    },
    units: {
      CoilType: '',
      CoilVoltage: 'Volt',
      ContactForm: '',
      ContactRating: '',
      SwitchingVoltage: '',
      TempMax: '',
      Termination: '',
    },
  },
  RF_Relay: {
    ranges: {
      CoilType: ['Latching Dual Coil', 'Non Latching', 'Latching Single Coil'],
      CoilVoltage: [1.5, 48.0],
      ContactForm: ['1 Form C', '2 Form C', '1 Form A', '2 Form A', 'Other'],
      ContactRating: [0.01, 2.0],
      SwitchingVoltage: [28.0, 500.0],
      TempMax: [60.0, 125.0],
      Termination: ['Surface Mount', 'Through Hole', 'Other'],
    },
    units: {
      CoilType: '',
      CoilVoltage: 'Volt',
      ContactForm: '',
      ContactRating: '',
      SwitchingVoltage: '',
      TempMax: '',
      Termination: '',
    },
  },
  Power_Relay: {
    ranges: {
      CoilType: ['Non Latching', 'Latching Single Coil', 'Latching Dual Coil'],
      CoilVoltage: [1.1, 480.0],
      ContactForm: [
        '1 Form B',
        '1 Form A',
        '1 Form C',
        '3 Form C',
        '2 Form C',
        '2 Form A',
        '1 Form A, 1 From B',
        '1 Form A, 1 Form B',
        '4 Form C',
        '3 Form A, 3 Form B',
        '3 Form A, 1 Form B',
        '6 Form A',
        '2 Form A, 2 Form B',
        '3 Form A',
        '1 Form A x 2',
        '4 Form A, 2 Form B',
        '5 Form A, 1 Form B',
        '1 Form Y',
        '2 Form B',
        '4 Form A',
        '1 Form A x 4',
        '1 Form A, 1 Form B x 2',
        '5 Form A, 2 Form B',
        '2 Form A, 1 Form B',
        '2 From B',
        '8 Form A',
        '1 Form A x 3',
        '1 Form A, 1 Form C',
        '5 Form C',
        '6 Form C',
        '8 Form C',
        '4 Form A, 4 Form B',
        '1 From C',
      ],
      ContactRating: [0.05, 600.0],
      SwitchingVoltageVAC: [
        'C 301-400',
        'E Not applicable',
        'A min-250',
        'D 401-max',
        'B 251-300',
      ],
      SwitchingVoltageVDC: [
        'D Not applicable',
        'A min-30',
        'C 126-max',
        'B 30-125',
      ],
      TempMax: [40.0, 125.0],
      Termination: [
        'Through-hole',
        'Plug In',
        'Other',
        'Screw Terminal',
        'Tab Terminal',
        'Chassis Mount',
        'SMT',
        'Through Hole',
      ],
    },
    units: {
      CoilType: '',
      CoilVoltage: 'Volt',
      ContactForm: '',
      ContactRating: '',
      SwitchingVoltageVAC: '',
      SwitchingVoltageVDC: '',
      TempMax: '',
      Termination: '',
    },
  },
  Signal_Relay: {
    ranges: {
      CoilType: ['Non Latching', 'Latching Dual Coil', 'Latching Single Coil'],
      CoilVoltage: [1.1, 240.0],
      ContactForm: [
        '2 Form C',
        '4 Form C',
        '6 Form A',
        '1 Form C',
        '6 Form C',
        '1 Form A',
        '2 Form A',
        '4 Form A',
        '2 Form D',
        '1 Form B',
        '(1 Form A) x 4',
        '8 Form C',
      ],
      ContactRating: [0.01, 6.0],
      SwitchingVoltageVAC: [
        'D Not applicable',
        'B 121-200',
        'A min-120',
        'C 201-Max',
      ],
      SwitchingVoltageVDC: [
        'A min-120',
        'B 121-200',
        'C 201-Max',
        'D Not applicable',
      ],
      TempMax: [40.0, 165.0],
      Termination: [
        'Other',
        'Plug In',
        'Through Hole',
        'Surface Mount',
        'Chassis Mount',
        'Screw Terminal',
      ],
    },
    units: {
      CoilType: '',
      CoilVoltage: 'Volt',
      ContactForm: '',
      ContactRating: '',
      SwitchingVoltageVAC: '',
      SwitchingVoltageVDC: '',
      TempMax: '',
      Termination: '',
    },
  },
};

export {
  productCategories,
  productCategoryNameByKey,
  productsByCategory,
  productNameByKey,
  productFeatures,
  minMaxFeatureValue,
  productCategoryFromProductType,
  sensorUnit,
  graphColors,
  specsData,
};
